require('./bootstrap');
require('jquery');

import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

import $ from 'jquery'
window.jQuery = $;
window.$ = $
